import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const home = Vue.component('home', require('../components/home.vue').default);

const routes = [
    {path:'/', component: home}
]

export default new VueRouter({
    routes,
    mode: 'history'
})