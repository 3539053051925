<template>
<div>
    <div class="container">
        <div class="row">
            <div class="col p-4" >
                <div class="card" v-if="stage==1">
                <div class="card-header">
                    <div>
                        <img src="/img/logo_blue.png" class="logo d-inline-block"> 
                        <div class="header_title d-inline-block">Warehouse Booking Portal</div> 
                    </div>
                </div>
                <div class="card-body">
                    <v-form ref="form">
                    <h5 class="card-title mb-4">Details</h5>
                    <p class="card-text">
                        
                         <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            max-width="290"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                :value="computedDateFormattedMomentjs"
                                clearable
                                outlined
                                label="Arrival Date"
                                class="mb-3"
                                hide-details=auto
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="book.date = null"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="book.date"
                                @change="menu = false"
                                :rules="inputRule"
                                class="mb-3"
                                :min="this.moment().format('yyyy-MM-DD')"
                                :max="this.moment().add(7, 'days').format('yyyy-MM-DD')"
                                required
                                hide-details=auto
                            ></v-date-picker>
                        </v-menu>
                        
                        <v-text-field
                        v-model="book.email"
                        :rules="emailRules"
                        label="Your Email"
                        outlined
                        required
                        class="mb-3"
                        ref="email"
                        hide-details=auto
                        ></v-text-field>

                        <v-text-field
                        v-model="book.client_name"
                        label="Customer Name"
                        outlined
                        required
                        class="mb-3"
                        :rules="[v => !!v || 'Customer Name is required']"
                        ref="customer_name"
                        hide-details=auto
                        ></v-text-field>

                        <v-text-field
                        v-model="book.client_ref"
                        label="Customer Reference or Container Number"
                        outlined
                        required
                        class="mb-3"
                        hide-details=auto
                        ></v-text-field>

                        <v-text-field
                        v-model="book.number_of_pallets"
                        label="Number of Pallets"
                        type="number"
                        min="1"
                        outlined
                        required
                        class="mb-3"
                        hide-details=auto
                        ></v-text-field>

                        <v-textarea
                        outlined
                        name="input-7-4"
                        label="Notes"
                        v-model="book.notes"
                        class="mb-3"
                        hide-details=auto
                        ></v-textarea>

                        <v-select
                            v-model="book.type"
                            :items="load_methods"
                            outlined
                            :rules="inputRule"
                            required
                            class="mb-3"
                            hide-details=auto
                            label="Select Load or Unload Activity"
                        ></v-select>
                        
                        <v-select
                            v-model="book.vehicle_class_id"
                            :items="VehicleClasses"
                            item-text="name"
                            item-value="id"
                            outlined
                            label="Your Vehicle Class"
                            :rules="inputRule"
                            hide-details=auto
                            class="mb-3"
                            required
                        >
                            <template v-slot:selection="data">
                                {{data.item.name}}
                                <v-chip class="text-white ml-3" v-if="data.item.requires_dual_dock" small color="green">Requires Dual Dock</v-chip>
                            </template>
                            <template v-slot:item="data">
                                {{data.item.name}} 
                                <v-chip class="text-white ml-3" v-if="data.item.requires_dual_dock" small color="green">Requires Dual Dock</v-chip>
                            </template>
                        </v-select>

                    </p>
                    <div v-if="book.vehicle_class_id && availableLoadPoints.length>0 && hasAvailableSlots">
                        <h5 class="card-title mb-4">Select Slot</h5>

                        <div v-for="l in availableLoadPoints" class="load_point"> 
                            <h6>{{l.name}}</h6>
                            <div 
                            v-for="(s, index) in generatedSlots(l)"
                            @click="selectSlot(l,s)"
                            class="slot_option" 
                            :class="{
                                selected:book.selectedSlot==s&& (book.selectedDock==l.id || book.selectedSecondaryDock==l.id), 
                                disabled:slotDisabled(l,s) || (requiresSecondDock&&!l.secondary_load_point_id),
                                }"
                            :key="index"
                            >
                                {{moment(s, "hh:mm").format('LT')}}
                            </div>
                        </div>
                    </div>



                    <div class="mb-5 alert alert-warning" v-if="!book.vehicle_class_id">
                        Select Vehicle Class to see available slots
                    </div>

                    <div class="mb-5 alert alert-warning" v-if="book.vehicle_class_id && (availableLoadPoints.length==0 || !hasAvailableSlots)">
                        No slots available today. Please try another day.
                    </div>


                    <div class="form_submit">
                        <v-btn @click="bookSlot()"  color="blue darken-4" class="btn btn-primary mt-4 mb-4 white--text">
                            Book 
                            <span v-if="book.selectedDock"> at <b>{{LoadPoints.find(x=>x.id==book.selectedDock).name}}
                            {{book.selectedSecondaryDock?' & '+SecondaryDock.name:''}}
                            {{' - '+ moment(book.selectedSlot, "hh:mm").format('LT')}}</b></span>
                        </v-btn>
                    </div>
                    
                    </v-form>
                </div>
                </div>
                
                <div class="card" v-if="stage==2">
                        <div class="success-animation">
                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                        <div class="green--text text-center mt-5"><h2>Booking Made Successfully!</h2></div>
                        <div class="text-center mt-5" style="font-size:20px">You have been sent a confirmation email to {{book.email}}</div>
                        <div class="text-center mt-5">
                            <h2>{{book.ref_no }}</h2><br>
                            <h2>{{moment(book.date).format('dddd DD/MM/YY')}}</h2><br>
                            <h2>{{this.LoadPoints.find(x=>x.id==book.selectedDock)?this.LoadPoints.find(x=>x.id==book.selectedDock).name:''}}</h2><br>
                            <h2 v-if="book.selectedSecondaryDock">{{this.LoadPoints.find(x=>x.id==book.selectedSecondaryDock)?'& '+this.LoadPoints.find(x=>x.id==book.selectedSecondaryDock).name:''}}</h2><br>

                            <h2>{{moment(book.selectedSlot, "hh:mm").format('LT')}}</h2>
                        </div>
                        <div class="text-center mt-5">
                            <v-btn color="blue darken-4" class="btn btn-primary mt-4 mb-4 white--text" @click="reset()">
                               Make another booking...
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
        <v-overlay :value="isBooking">
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
    </v-overlay>
    </div>
</template>

<script>
export default {
    props:['newBook', 'selectedDate'],
    data() {
        return{
            isBooking:false,
            client_name: '',
            email: '',
            forklift: '',
            valid:false,
            stage:1,
            menu:false,
            book:{
                type:'unload',
                email:'',
                vehicle_class_id:null,
                date:this.moment().format('yyyy-M-D'),
                selectedDock:'',
                selectedSecondaryDock:'',
                selectedSlot:'',
                status:'pending',
            },
            load_methods: [
                'load',
                'unload'
            ],

            VehicleClasses:[],
            LoadPoints:[],
            bookings:[],
            inputRule: [
                v=> !!v
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
        }
    },

    computed: {

      hasAvailableSlots(){
          var hasAvail = false
          this.availableLoadPoints.forEach(l=>{
              this.generatedSlots(l).forEach(s=>{
                if(this.requiresSecondDock){
                    if(l.secondary_load_point_id){
                        if(!this.slotDisabled(l,s)){
                            hasAvail = true
                        }
                    }
                } else {
                    if(!this.slotDisabled(l,s)){
                        hasAvail = true
                    }
                }
              })
          })

          return hasAvail
      },

      vehicle_class() {
          var vehicle_class = this.VehicleClasses.find(x=>x.id==this.book.vehicle_class_id)
          return vehicle_class?vehicle_class:false
      },

      SecondaryDock() {
          if(this.book.selectedSecondaryDock){
              return this.LoadPoints.find(x=>x.id==this.book.selectedSecondaryDock)
          }
      },

      requiresSecondDock(){
          return this.vehicle_class && this.vehicle_class.requires_dual_dock?true:false
      },

      computedDateFormattedMomentjs () {
        return this.book.date ? this.moment(this.book.date).format('dddd, MMMM Do YYYY') : ''
      },
      computedDateFormattedDatefns () {
        return this.book.date ? format(parseISO(this.book.date), 'EEEE, MMMM do yyyy') : ''
      },

      availableLoadPoints() {
          var lp = []
          var booking_day = this.moment(this.book.date).format('dddd').toLowerCase()
          this.LoadPoints.forEach(function(l){
              // Check Active Load Point
              if(l.active) {
              // Check Active During Day
                if(l[booking_day+'_active']){
                    lp.push(l)
                }
              }
          })
          return lp.filter(l=> l.VehicleClassAllowances.includes(this.book.vehicle_class_id) && l.active)
      },

      availableSlots() {
          if(this.book.vehicle_class_id){

          }
      }
    },

    mounted() {
        this.getLoadPoints()
        this.getVehicleClasses()
        this.getBookings()
        // if(this.newBook.lp.length>0){
        //     this.book.selectedDock = this.newBook.lp
        //     this.book.selectedSlot = this.newBook.s
        // }
        this.availableLoadPoints
        this.book.date = this.moment(this.selectedDate).format('yyyy-M-D')
    },

    methods: {
        slotDisabled(load, slot){
            var bool = false
            // Check if is in future
            var slot_time = this.moment(this.book.date+' '+slot, 'yyyy-M-D hh:mm')
            var current_time = this.moment()
            if(current_time.isAfter(slot_time)){
                bool = true
            }

            // Check if requires secondary dock and secondary dock is available
            var vehicle_class = this.VehicleClasses.find(x=>x.id==this.book.vehicle_class_id)
            if(vehicle_class.requires_dual_dock){
        
                var secondary_load = this.LoadPoints.find(x=>x.id==load.secondary_load_point_id)
                if(secondary_load){
                    
                    var notavail = this.slotDisabled(secondary_load, slot)
                    bool = notavail
                }
            }
            

            // Check Bookings
            this.bookings.forEach(function(b){
                // Not Cancelled
                var isCancelled = b.status=='Cancelled / No Show'?true:false

                // date match
                var isDated = this.moment(this.book.date).format('yyyy-M-D')==this.moment(b.date).format('yyyy-M-D')?true:false;

                // slot match
                // var minutes = this.VehicleClasses.find(x=>x.id==this.book.vehicle_class_id).load_time
                var minutes = this.VehicleClasses.find(x=>x.id==this.book.vehicle_class_id).load_time
                var booking_start = this.moment(b.start_time, 'HH:mm').format('HH:mm');
                var booking_end = this.moment(b.start_time, 'HH:mm').add(b.duration, 'minutes').format('HH:mm');
                
                var slot_start = this.moment(slot, 'HH:mm').format('HH:mm');
                var slot_end = this.moment(slot, 'HH:mm').add(minutes, 'minutes').format('HH:mm');
                var isSlotted = this.checkOverlap([booking_start, booking_end],[slot_start,slot_end])

                // load point match
                var isLoaded = load.id==b.load_point_id || load.id==b.secondary_load_point_id?true:false

                if(isDated && isSlotted && isLoaded && !isCancelled) {
                    bool = true
                }
            }.bind(this))


            return bool
        },

        getBookings() {
            axios.post('/booking/read', {date:this.book.date}).then(response=>{
                this.bookings = response.data
            }).catch(error=>{
                alert(error.data.data.message)
            })
        },

        reset() {
            this.stage=1,
            this.book = {
                    type:'unload',
                    duration:0,
                    vehicle_class_id:null,
                    date:this.moment().format('yyyy-M-D'),
                    selectedDock:'',
                    selectedSlot:'',
                    status:'pending',
            }
        },

        bookSlot() {
            if(this.isBooking==false){
                if(!this.$refs['form'].validate()){
                    window.scrollTo({top: 0, behavior: 'smooth'});
                }else if(!this.book.selectedSlot){
                    alert('Select a Time Slot')
                } else {
                    this.isBooking=true
                    axios.post('/booking/create', this.book).then(response=>{
                        this.$emit('bookingSuccess', response.data)
                        this.book.ref_no=response.data.ref_no
                        this.stage = 2
                        this.getBookings()
                        this.isBooking=false
                    }).catch(error=>{
                        this.isBooking=false
                    })
                }
            }
        },

        selectSlot(l,s){
            if(!this.slotDisabled(l,s) || (requiresSecondDock&&!l.secondary_load_point_id)){
                var vehicle_class = this.VehicleClasses.find(x=> x.id == this.book.vehicle_class_id)
                this.book.selectedDock=l.id
                this.book.selectedSlot=s
                this.book.duration= vehicle_class.load_time

                if(vehicle_class.requires_dual_dock){
                   this.book.selectedSecondaryDock=l.secondary_load_point_id
                } 
            }
        },

        filteredLoadPoints() {
            return this.LoadPoints
        },

        generatedSlots(l) {
            var start = null
            var end = null
            const date = moment(this.book.date);
            const booking_day = date.format('dddd').toLowerCase();
            const dow = date.day();

            if(!l[booking_day+'_active']){
                return []
            }

            switch(dow) {
                case 1:
                    start = l.monday_open
                    end = l.monday_close
                    break;
                case 2:
                    start = l.tuesday_open
                    end = l.tuesday_close
                    break;
                case 3:
                    start = l.wednesday_open
                    end = l.wednesday_close
                    break;
                case 4:
                    start = l.thursday_open
                    end = l.thursday_close
                    break;
                case 5:
                    start = l.friday_open
                    end = l.friday_close
                    break;
                case 6:
                    start = l.saturday_open
                    end = l.saturday_close
                    break;
                case 0:
                    start = l.sunday_open
                    end = l.sunday_close
                    break;
            }

            var slots = []

            //Data
            let x = {
                slotInterval: this.VehicleClasses.find(c=>c.id==this.book.vehicle_class_id).load_time,
                openTime: this.moment(start, 'hh:mm'),
                closeTime: this.moment(end, 'hh:mm')
            };


            var startTime = this.moment(x.openTime, "HH:mm");
            let endTime = this.moment(x.closeTime, "HH:mm");




            let allTimes = [];

            // while (startTime < endTime) {   
            //     var time = startTime.format("HH:mm");
            //     allTimes.push(time);
            //     startTime = startTime.add(180, 'minutes');
            // }

            while (startTime < endTime) {
                var time = startTime.format("HH:mm");
                let booking_time = moment(startTime).add(x.slotInterval, 'minutes');
                startTime.add(l.slot_duration, 'minutes');
               // startTime.add(l.slot_duration, 'minutes');
                if(booking_time<=endTime){
                    allTimes.push(time);
                }
            }

            return allTimes
        },
        
        getVehicleClasses() {
            axios.post('/vehicleclass/read').then(response=>{
                this.VehicleClasses = response.data
            })
        },

        getLoadPoints() {
            axios.post('/loadpoint/read').then(response=>{
                this.LoadPoints = response.data
            })
        },

        checkOverlap(date1, date2){
            var bool = false
            var range = this.moment.range([this.moment(date1[0], 'HH:mm'),this.moment(date1[1], 'HH:mm')]);
            var range2 = this.moment.range([this.moment(date2[0], 'HH:mm'),this.moment(date2[1], 'HH:mm')]);
            // has overlapping
            if (range.overlaps(range2)) {
                if ((range2.contains(range, true) || range.contains(range2, true)) && !this.moment(date1[1], 'HH:mm').isSame(this.moment(date2[1], 'HH:mm')))
                    bool=true
                else
                    bool=true
            } else {
                bool=false
            }
    
            return bool
        },
    },

    watch: {
        selectedDate: function(){
            this.book.date = this.moment(this.selectedDate).format('yyyy-M-D')
            this.getBookings()
        },

        'book.date':{
            handler(){
                this.getBookings()
            }
        },

        'book.vehicle_class_id': {
            handler() {
                this.book.selectedDock=''
                this.book.selectedSecondaryDock=''
                this.book.selectedSlot=''
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.disabled {
    border:#eee solid 1px;
    color:#ddd;
    background:#f8f8f8;
    pointer-events:none;
}

.logo {
    max-width:150px;
    margin-right:16px;
}

.load_point {
    margin-bottom:16px;
}

h6 {
    font-weight:bold;
    margin-bottom:1em;
    border-bottom:1px #ccc solid;
    padding-bottom:1em;
}

.slot_option {
    padding:8px 16px;
    border:1px solid #ccc;
    border-radius:5px;
    display:inline-block;
    width:128px;
    margin:0px 8px 8px 0px;
    cursor:pointer;

    &:hover:not(.disabled) {
        background:#3b7596;
        color:#fff;
    }
}

.select_class {
    padding:16px;
    font-size:16px;
    border:1px #ccc solid;
    background:#eee;
}

.selected {
    border:#116495 1px solid;
    color:#fff;
    background:#116495
}

.unselectable{
    cursor: not-allowed !important;
}

.success-animation { margin:40px auto;}

.checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #4bb71b;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #4bb71b;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position:relative;
    top: 5px;
    right: 5px;
   margin: 0 auto;
}
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #4bb71b;
    fill: #fff;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
 
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.header_title {
    font-size:16px;
    color:#555;
    line-height: 30px;
    font-weight:bold;;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #4bb71b;
    }
}

.form_submit {
    position: sticky;
    bottom: 0;
    z-index: 2;
    background: #fff;
}

</style>