require('./bootstrap');

window.Vue = require('vue').default;
import router from './router'

import Vue from 'vue';
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
Vue.use(Vuetify)

// toasts
import { BreadstickBakery } from 'breadstick'
Vue.use(BreadstickBakery)

import moment from 'moment'
Vue.prototype.moment = moment
import { extendMoment } from 'moment-range';


import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css';


window.moment = extendMoment(moment)

const app = new Vue({
    router: router,
    el: '#app',
    vuetify: new Vuetify({
        theme: {
            // dark: true,
            
            themes: {
                light: {
                    primary: '#116495',
                    accent: '#B6CFEB',
                    danger: '#C23B23',
                    info: '#579ABE',
                    success: '#03C03C',
                    warning: '#F39A27',
                    secondary: '#3490dc',
                    pending: '#adbafc',
                    hold: '#f8b051',
                }
            }
        },
        icons: {
            iconfont: 'mdi',
          },
    })
});
